import { shareSNS } from './lib/_share.js';
import { kvAnime } from './_kv.js';

export var common = {
	scrl: "",
	beforeW: 0,
	init: ()=>{
		common.start();
	},
	start: function(){
		let $body = document.getElementsByTagName('body')[0];
		
		common.resize();
		common.nav();
	},
	nav: ()=>{
	},
	resize: ()=>{
		var winW 	= window.innerWidth;
		var winH 	= window.innerHeight;
		let $body 	= document.getElementsByTagName('body')[0];
		
		addressbarH();
		let isPage = $body.getAttribute("data-page");
		if( isPage === "top" ){
			kvAnime.resize();
		}
		setTimeout(function(){
			if( $body.classList.contains("resize") ){
				$body.classList.remove("resize");
			}
		},300);
		function addressbarH(){
			if(common.beforeW != winW){
				// 100vh、アドレスバーが出てる時に対応
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty("--vh", `${vh}px`);
			}
		}
		common.beforeW = winW;
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	},
	removeMultipleClass: function(dom, className){
		const target = document.querySelector(dom);
		const targetClassList = target.className;
		const myRegExp = new RegExp("/\b" + className + "\S+/", 'g');
		const myMatched = targetClassList.match(myRegExp) || [];
		for(let n = 0; n < myMatched.length; n++) {
		  target.classList.remove(myMatched[n]);
		}
	}
}
