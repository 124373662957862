import { object } from './lib/_obj.js';
import { getEasing } from './lib/_easing.js';
import {TwistFilter} from '@pixi/filter-twist';

let pageImgs = {
	pos: [0, 0],
	angle: [],
	rotate: 0,
	scale: [0, 0],
	currentPos: []
}
export var kvAnime = {
	pages: [],
	hankei: 200,
	init: function(){
		let imgs = document.querySelectorAll(".paper_imgs ul li");
		let angles = [];
		for(let i = 0; i < imgs.length; i++){
			let a = 360/imgs.length * i;
			angles.push(a);
		}
		angles = shuffle(angles);
		kvAnime.pages = [];
		imgs.forEach( (p, index) => {
			let _i = object(pageImgs);
			_i.angle = angles[index] + Math.floor( Math.random()*20 ) - 10;
			_i.scale = [0, 0];
			_i.scale[0] = 1 + Math.random()*100/100;
			_i.scale[1] = 1 + Math.random()*100/100;
			
			_i.pos = [0, 0];
			_i.pos[0] = Math.cos( Math.PI/180 * _i.angle )*_i.scale[0];
			_i.pos[1] = Math.sin( Math.PI/180*_i.angle )*_i.scale[1];
			_i.currentPos = [0, 0];
			_i.rotate = Math.floor( Math.random()*180 );
			
			//console.log(_i.pos)
			kvAnime.pages.push(_i);
		});
		kvAnime.resize();
		logo.init();
	},
	resize: function(){
		let imgs = document.querySelectorAll(".paper_imgs ul li");
		let winW = window.innerWidth;
		let hankei = winW*.2;
		//console.log(imgs);
		kvAnime.pages.forEach( (p, index) => {
			p.currentPos[0] = p.pos[0]*hankei;
			p.currentPos[1] = p.pos[1]*hankei;
			imgs[index].style.transform = `translate3d(${p.currentPos[0]}px, ${p.currentPos[1]}px, 0) rotate(${p.rotate}deg)`;
		});
	},
	react: function(topD){
		
	},
	setSvg: function(){
		
	}
}

let twistOption = {
	radius: 0,
	angle: 0,
	scale: 1,
	padding: 200
}
export let logo = {
	stage: null,
	screen: null,
	renderer: null,
	loader: null,
	app: null,
	points: null,
	pointCount: 20,
	length: 500,
	easing: null,
	init: function(){
		if(logo.app){
			
		}else{
			logo.easing = getEasing("easeOutQuad");
			window.addEventListener('load', () => {
				PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;
			});
			const pixelRatio = window.devicePixelRatio || 1;
			logo.app = new PIXI.Application({
				width: window.innerWidth,
				height: window.innerHeight,
				backgroundAlpha: 0,
				resolution: pixelRatio,
				antialias: true,
				autoDensity: true
			});
			document.querySelector("#logo_canvas").appendChild(logo.app.view);
			logo.loadLogo();
		}
	},
	loadLogo: function(){
		let winW = window.innerWidth;
		//変形のためのメッシュを作成
		let ratio 		= 739/4657;
		logo.length 	= 739;
		logo.pointCount = 20;
		
		logo.length = 739/logo.pointCount;
		logo.points = [];
		for(let i = 0; i < logo.pointCount; i++){
			logo.points.push( new PIXI.Point(i*logo.length, 0) );
		}
		//logo.points = Array(logo.pointCount).fill(0).map((_, index) => new PIXI.Point(logo.length * (index / (logo.pointCount - 1)), 0))
		let imgURL = "";
		if (window.matchMedia('all and (min-width : 768px)').matches) {
			imgURL = "/assets/images/kv/kv_logo0.png";
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			imgURL = "/assets/images/kv/kv_logo0_sp.png";
		}
		logo.img 		= new PIXI.SimpleRope(PIXI.Texture.from(imgURL), logo.points);
		logo.container	= new PIXI.Container();
		logo.resize();
		logo.container.pivot.x = logo.container.width/2;
		logo.container.pivot.y = logo.container.height/2;
		
		logo.app.stage.addChild(logo.container);
		logo.container.addChild(logo.img);
		logo.animate();
	},
	resize: function(){
		let winW = window.innerWidth;
		let winH = window.innerHeight;
		logo.app.renderer.resize(winW, winH);
		
		logo.app.width	= winW;
		logo.app.height = winH;
		
		logo.img.width 	= winW*0.83125;
		logo.img.height = logo.img.width*(739/4657);
		logo.img.x 		= -logo.img.width/2;
		logo.img.y 		= logo.img.height/2;
		
		logo.container.x = winW/2;
		logo.container.y = (winH - logo.img.height)/2;
		
		//console.log(logo.img.width, logo.img.height);
	},
	animate: function(){
		// メッシュのポイントをSin波でフレームごとに動かして変形
		logo.app.ticker.add(() => {
			const time = performance.now() / 1000;
			let len = logo.length / logo.pointCount;
			for (let i = 0; i < logo.points.length; i++) {
				//logo.points[i].y = Math.sin((i * 0.5) + time);
				logo.points[i].x = i * logo.length;// + Math.cos((i * 0.3) + time) * 20
			}
			logo.resize();
		});
		logo.applyFilter();
	},
	applyFilter: function(){
		let options = {
			radius: twistOption.radius,
			angle: twistOption.angle,
			padding: twistOption.padding,
			offset: [logo.img.width/2, window.innerHeight/2]
		}
		let twistFilter = new TwistFilter(options);
		
		let p = 0;
		function updateFilter(){
			//twistFilter.padding = Math.sin(p);
			twistFilter.angle = twistOption.angle;
			twistFilter.radius = twistOption.radius;
			//console.log(twistOption.scale)
			logo.container.scale.set(twistOption.scale, twistOption.scale);
			requestAnimationFrame(updateFilter);
		}
		updateFilter();
		logo.container.filters = [twistFilter];
	},
	nextScene: function(duration){
		let animateTimer;
		let animeEnd = duration;
		let begin = new Date() - 0;
		
		let radiusEnd 	= logo.img.width/2;
		let angleEnd 	= 1;
		let rotateEnd 	= -.6;
		let scaleEnd 	= .6;
		clearInterval(animateTimer);
		animateTimer = setInterval( ()=> {
			let current = new Date() - begin;
			//console.log(current, animeEnd);
			if(current >= animeEnd){
				current = animeEnd;
				clearInterval(animateTimer);
			}
			//let progress = 1 - current/duration;
			let progress = current/animeEnd;
			let easeProgress = logo.easing(progress, 0, 1, 1);
			twistOption.radius 	= radiusEnd*progress;
			twistOption.angle 	= angleEnd*progress;
			twistOption.scale 	= 1 - (1- scaleEnd)*progress;
			logo.container.rotation = rotateEnd*progress;
			if(progress >= 1){
				clearInterval(animateTimer);
			}
		}, 33);
	},
	backScene: function(duration){
		let animateTimer;
		let animeEnd = duration;
		let begin = new Date() - 0;
		
		let radiusEnd 	= logo.img.width/2;
		let angleEnd 	= 1;
		let rotateEnd 	= -.6;
		let scaleEnd 	= .6;
		clearInterval(animateTimer);
		animateTimer = setInterval( ()=> {
			let current = new Date() - begin;
			//console.log(current, animeEnd);
			if(current >= animeEnd){
				current = animeEnd;
				clearInterval(animateTimer);
			}
			//let progress = 1 - current/duration;
			let progress = current/animeEnd;
			let easeProgress = logo.easing(progress, 0, 1, 1);
			twistOption.radius 	= radiusEnd - radiusEnd*progress;
			twistOption.angle 	= angleEnd - angleEnd*progress;
			twistOption.scale 	= (1 - scaleEnd) + (scaleEnd)*progress;
			logo.container.rotation = rotateEnd - rotateEnd*progress;
			if(progress >= 1){
				clearInterval(animateTimer);
			}
		}, 33);
	},
	endScene: function(duration){
		let endTimer;
		let animeEnd = duration;
		let begin = new Date() - 0;
		let radiusEnd = logo.img.width;
		let angleEnd = .2;
		let scaleEnd = .1;
		let rotateEnd = 1.5;//前の段階で.6
		clearInterval(endTimer);
		endTimer = setInterval( ()=> {
			let current = new Date() - begin;
			//console.log(current, animeEnd);
			if(current >= animeEnd){
				current = animeEnd;
				clearInterval(endTimer);
			}
			//let progress = 1 - current/duration;
			let progress 		= current/animeEnd;
			let easeProgress 	= logo.easing(progress, 0, 1, 1);
			twistOption.radius 	= logo.img.width/2 + (radiusEnd)*progress;
			//twistOption.angle 	= twistOption.angle + (twistOption.angle + angleEnd )*progress;
			//twistOption.scale 	= 1 - (1- scaleEnd)*progress;
			
			logo.container.rotation = -.6 + (rotateEnd*progress);
			if(progress >= 1){
				clearInterval(endTimer);
			}
		}, 33);
	}
}
const shuffle = ([...array]) => {
	for (let i = array.length - 1; i >= 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
}