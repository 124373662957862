import { preload } from './lib/_preload.js';
import { loc } from './lib/_location.js';
import { Scroll, ScrollTo } from './lib/_scroll.js';
import { common } from './_common.js';
import { kvAnime } from './_kv.js';

let $html = document.documentElement;
let $this;
export class Framework {
	constructor(){
		$this = this;
		this.firstVisit = true;
		this.canClick 	= true;
		this.pageinfo = loc.all();
		this.nextpage = "";
		this.scroll = new Scroll();
		this.checkURL();
		$this.scroll.checkBlock();
		$this.scroll.checkBackground();
		$this.nav();
		
		this.resizeFlag;
		this.resizeTimer = null;
		/* window resize
		----------------------------- */
		this.resizeCallback = function() {
			common.resize();
			$this.scroll.checkBlock();
			$this.scroll.checkBackground();
			$this.resizeTimer = null;
		};
		window.addEventListener("resize", function(){
			let $body = document.querySelector("body");
			if( !$body.classList.contains("resize") ){
				$body.classList.add("resize");
			}
			if($this.resizeTimer) {
				clearTimeout($this.resizeTimer);
			}
			$this.resizeTimer = setTimeout($this.resizeCallback, 300);
		}, false);
	}
	
	checkURL(){
		if( $this.firstVisit ){
			$this.firstVisit = false;
			$this.afterChangePage();
		}
	}
	
	nav(){
		let internalLinks = document.querySelectorAll("[data-internallink]");
		internalLinks.forEach( (internallink) => {
			internallink.removeEventListener("click", $this.getURL, false);
			internallink.addEventListener("click", $this.getURL, false);
		});
	}
	getURL(e){
		e.preventDefault();
		let url = this.getAttribute("href");
		$this.changeURL(url);
	}
	activeNav(){
		let internalLinks = document.querySelectorAll("[data-internallink]");
		let currentURL = location.pathname;
		internalLinks.forEach( (internallink) => {
			let thisLink = internallink.getAttribute("href");
			console.log(thisLink, currentURL);
			if(thisLink === currentURL){
				internallink.classList.add("active");
			}else{
				if( internallink.classList.contains("active") ){
					internallink.classList.remove("active");
				}
			}
		});
	}
	changeURL(url){
		window.history.pushState(null, null, url);
		let $article = document.querySelector("article");
		if( !$article.classList.contains("disable") ){
			$article.classList.add("disable");
		}
		setTimeout(function(){
			$this.changeContent(url).then(function(){
				//console.log("loaded content");
				let posY = document.querySelector("article").getBoundingClientRect().top + window.pageYOffset;
				ScrollTo.start(posY, 100);
				setTimeout(function(){
					$this.afterChangePage();
				}, 100);
			});
		}, 600);
	}
	
	changeContent(url){
		return new Promise( (resolve, reject) => {
			fetch(url).then(response=> response.text())
			.then((text) => {
				const $body = document.querySelector("body");
				const parser 		= new DOMParser();
				let loadedContent 	= parser.parseFromString(text, "text/html"),
					ttl 			= loadedContent.title, //ページタイトル
					$content 		= document.querySelector(".load"),	//書き換えるDOM
					content 		= loadedContent.querySelector(".load_content"), //ロードしたコンテンツの中身
					contentBody		= loadedContent.querySelector("body");
				let pagename = contentBody.getAttribute("data-pagename");
			
				//console.log(content);
				$content.textContent = "";
				$body.setAttribute("data-pagename", pagename);
				$content.insertAdjacentElement("afterbegin", content);
				document.title = ttl; //ページタイトルを書き換え
				
				resolve();
			});
		});
		
	}
	
	afterChangePage(){
		let $body = document.querySelector("body");
		if(this.firstVisit){
			this.firstVisit = false;
			common.init();
		}
		$this.activeNav();
		let dataID = document.querySelector("body").getAttribute("data-page");
		kvAnime.init();
		common.resize();
		common.nav();
		$this.scroll.checkBlock();
		$this.reset();
		$this.nav();
		let $article = document.querySelector("article");
		if( $article.classList.contains("disable") ){
			$html.classList.add("scrolled");
			$this.scroll.checkBlock();
			$article.classList.remove("disable");
		}
		if( $body.classList.contains("disable") ){
			setTimeout(function(){
				$this.scroll.checkBlock();
				$body.classList.remove("disable");
			}, 600);
		}
	}
	
	reset(){
		this.canClick = true;
	}
}