import { object } from './_obj.js';
import { getEasing } from './_easing.js';
import { logo } from '../_kv.js';
let Block = {
	top: 0,
	show: 0
}
let $html = document.documentElement;
let bgs = [];
/*
座標
elem.getBoundingClientRect()
*/
let $this;
export class Scroll {
	constructor(){
		$this = this;
		this.topD = 0;
		this.btmD = this.topD + window.innerHeight;
		this.animations = new Array();
		this.blocks 	= new Array();
		this.articleTop = 0;
		this.checkBlock();
		this.checkScroll();
		document.addEventListener("scroll", ()=>{
			$this.topD = window.pageYOffset;
			$this.checkScroll();
		}, false);
	}
	
	checkScroll(){
		$this.btmD = $this.topD + window.innerHeight;
		$this.articleTop = document.querySelector("article").getBoundingClientRect().top + window.pageYOffset;
		for(let index in $this.animations){
			if( $this.btmD >= $this.animations[index].top[1] ){
				$this.activeAnimate(index);
			}
		}
		//console.log($this.btmD, window.innerHeight/3)
		if( $this.btmD >= window.innerHeight*2 + window.innerHeight/3 ){
			aCls($html, "scrolled");
		}else{
			rCls($html, "scrolled");
		}
		let $pageList = document.querySelector(".paper_imgs ul");
		if( $this.topD >= 100 ){
			if( $pageList.classList.contains("pause") ){
				$pageList.classList.remove("pause");
				logo.nextScene(300);
				aCls($html, "scene_next");
			}
		}else{
			if( !$pageList.classList.contains("pause") ){
				$pageList.classList.add("pause");
				logo.backScene(200);
				rCls($html, "scene_next");
			}
		}
		if( $this.topD >= $this.articleTop ){
			aCls($html, "area_article");
		}else{
			rCls($html, "area_article");
		}
		
		bgs.forEach( (bg, index) => {
			if( index === bgs.length -1 ){
				if( $this.topD >= bgs[bgs.length - 1] ){
					activeBg(index);
				}
			}else{
				if( $this.topD >= bgs[index] && $this.topD < bgs[index+1] ){
					activeBg(index);
				}
			}
		});
		function activeBg(n){
			let backgrounds = document.querySelectorAll("#bg_article figure");
			if( !backgrounds[n].classList.contains("active") ){
				backgrounds.forEach( (bg, index) => {
					if( n === index ){
						backgrounds[index].classList.add("active");
					}else{
						backgrounds[index].classList.remove("active");
					}
				})
			}
		}
	}
	checkBackground(){
		let backgrounds = document.querySelectorAll("#bg_article figure");
		bgs = [];
		var body = document.body,
			html = document.documentElement;
		
		let docH = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight ) - $this.articleTop;
		let perH = docH/backgrounds.length;
		backgrounds.forEach( (bg, index) => {
			bgs.push( perH*index + $this.articleTop );
		});
		//console.log(bgs);
	}
	activeAnimate(i){
		let $a = $this.blockA[i];
		if( !$a.classList.contains("animate") ){
			$a.classList.add("animate");
			setTimeout(function(){
				$a.classList.add("animated");
			}, 1000);
		}
	}
	//アニメーションのブロックをチェック
	checkBlock(){
		const Animate = {
			top: []
		}
		$this.blockA = document.querySelectorAll(".a");
		$this.animations = [];
		$this.blockA.forEach( (item) => {
			let _a = object(Animate);
			_a.top = $this.getTop(item, window.innerHeight/3);
			$this.animations.push(_a);
		});
		
	}
	getTop($dom, offset){
		let t = Math.floor( $dom.getBoundingClientRect().top + window.pageYOffset );
		return [ t, Math.floor( t + offset )]
	}
	
	scrollToPos(posY){
		console.log("scroll to > ", posY);
		window.scrollTo({
			top: posY,
			behavior: "smooth"
		});
	}
}

export let ScrollTo = {
	begin: 0,
	yOffset: 0,
	end: 0,
	timer: null,
	start: function(y, duration){
		let $this = ScrollTo;
		$this.end = y;
		//console.log(y, duration);
		$this.begin = new Date() - 0;
		$this.yOffset = window.pageYOffset;
		//console.log($this.yOffset, y);
		
		clearInterval($this.timer);
		let ease = getEasing("easeInOutCubic");
		let max = y - window.pageYOffset;
		$this.timer = setInterval( ()=> {
			let current = new Date() - $this.begin;
			if(current >= duration){
				current = duration;
				clearInterval($this.timer);
			}
			//let progress = 1 - current/duration;
			let progress = current/duration;
			let easeProgress = ease(progress, 0, 1, 1);
			//console.log(easeProgress);
			let currentPos = max*easeProgress + $this.yOffset;
			//console.log(max*easeProgress, window.pageYOffset);
			window.scrollTo(0, currentPos );
		}, 33);
	}
}
function aCls($dom, className){
	if( !$dom.classList.contains(className) ){
		$dom.classList.add(className);
	}
}
function rCls($dom, className){
	if( $dom.classList.contains(className) ){
		$dom.classList.remove(className);
	}
}